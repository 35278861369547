<template>
	<section class="columns is-multiline is-mobile">
		<Title />
		<div v-if="visible" class="page__actions column">
			<slot></slot>
		</div>
		<Weather :bordered="bordered" />
		<Notifications />
	</section>
</template>

<script>
import Title from '@/components/Title'
import Weather from '@/components/Weather'
import Notifications from '@/components/Notifications'

export default {
	name: 'Header',
	components: {
		Title,
		Weather,
		Notifications
	},
	props: {
		bordered: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			visible: true
		}
	},
	computed: {
		name() {
			return this.$route.name
		}
	},
	mounted() {
		// console.log(this.$scopedSlots)
		const slot = this.$slots.default
		this.visible = slot && slot.length > 0 ? true : false
	}
}
</script>
