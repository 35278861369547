<template>
	<nav class="breadcrumb is-small has-dot-separator" aria-label="breadcrumbs">
		<ul>
			<li>
				<span class="is-size-6" v-if="current == 'Dashboard'">Welcome to Volvo Excellence Program.</span>
				<router-link v-else :to="{ name: 'Dashboard' }">Volvo Excellence Program</router-link>
			</li>
			<li v-if="parent">
				<a :href="parent.path">{{ parent.meta.title ? parent.meta.title : parent.name }}</a>
			</li>
			<li class="is-active is-regular" v-if="current != 'Dashboard'">
				<a href="#" aria-current="page">{{ current }}</a>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: 'Breadcrumb',
	props: {
		links: {
			type: [Array, Object]
		}
	},
	computed: {
		parent() {
			let r = this.$route,
				m = r.matched

			return m.length > 1 ? m[0] : false
		},
		current() {
			let r = this.$route,
				m = r.meta

			return m.title ? m.title : r.name
		}
	}
}
</script>
