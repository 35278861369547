<template>
	<header class="page__title column is-12-mobile">
		<h1 class="title has-text-grey-dark is-size-3-touch mb-0">
			<span v-if="page == 'Dashboard' && user">Hello, {{ user.first_name }}!</span>
			<span v-else>{{ page }}</span>
		</h1>
		<Breadcrumb />
	</header>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { mapState } from 'vuex'

export default {
	name: 'Title',
	components: {
		Breadcrumb
	},
	data() {
		return {
			username: '',
		}
	},
	computed: {
		...mapState('user', ['user']),
		page() {
			let r = this.$route,
				m = r.matched

			return m ? (m[0].meta.title ? m[0].meta.title : m[0].name) : r.name
		}
	}
}
</script>
